<template>
    <div>
        <CModalExtended
            size="sm"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.bug_list')"
            :show.sync="$store.state.planificacionestiba.modalBugList"
        >
            <div style="background:#FFF;" class="m-2">
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    :loading="apiStateLoading"
                    column-filter
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :items="dateFilteredItems"
                    :fields="fields"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    :items-per-page="5"
                    pagination
                     
                >
                    <template #id="{ index}">
                        <td class="center-cell text-center">
                        {{index+1}}
                        </td>
                    </template>
                </dataTableExtended>
            </div>
            
            <div slot="footer">
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import DataStorage from '@/_helpers/localStorage';
    //data
    function data() {
        return {
            usersOpened: null,
            padre: '',
        }
    }
    //methods
    function cerrarModal(){
        this.$store.state.planificacionestiba.modalBugList = false;
    }
    //computed
    function fields(){
        return [
            { key: 'id', label: '#',filter :false, _style:'width:5%; text-align:center;' },
            { key: 'messageDescription', label: this.$t('label.decription'),_classes:"text-left", _style:'width:50%;' },
            { key: 'ColumnName', label: this.$t('label.attribute'),_classes:"text-left", _style:'width:23%;'},
            { key: 'ColumnValue', label: this.$t('label.value'),_classes:"text-left", _style:'width:22%;'},
        ]
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function dateFilteredItems(){
        if(this.modalBugList){
            let local = DataStorage.getLocale();
            let itemx = this.items.ErrorJson.map((item) => Object.assign({}, item, {
                messageDescription : local == 'es'? item.EdiMessageSpanish : item.EdiMessageEnglish
            }));
            return itemx.filter(async (item) => {
                return item
            });
        }else{
            return [];
        }
    }
    export default {
        name:'modal-bug-list',
        data,
        props: {
            items: {
                type: Object,
                required: true,
                default: () => {},
            },
        },
        methods:{
            cerrarModal
        },
        computed:{
            fields,
            tableTextHelpers,
            apiStateLoading,
            dateFilteredItems,
            ...mapState({
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                modalBugList: state => state.planificacionestiba.modalBugList,
            })
        }
    }
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>